import React from 'react'

import { useQuery } from '@/common/hooks'

import JoinLeadAcceptInvite from './joinLeadAccepInvite'

const JoinLead = _props => {
  const query = useQuery()
  const offer = query.get('promo_code')
  const token = query.get('invite')

  return <JoinLeadAcceptInvite offer={offer} token={token} />
}

export default JoinLead
