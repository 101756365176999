import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const rootPersistConfig = {
  blacklist: ['loading', 'netspend', 'team', 'toasts', 'user'],
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const configureStore = state => {
  const middleware = [thunk]

  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    persistedReducer,
    state,
    composeEnhancer(applyMiddleware(...middleware))
  )
  const persistor = persistStore(store, null, () => {
    // this will be invoked after rehydration is complete.
    // helpful for reinitializing loggers, etc.
  })

  return { persistor, store }
}

export const { store, persistor } = configureStore()
