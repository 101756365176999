export const ENDPOINTS = {
  ACCEPT_FRIEND: '/users/friends',
  ACCEPT_INSIDER: '/users/insiders',
  ACCEPT_LEAD: '/users/leads',
  ACCEPT_MEMBER: '/users/members',
  FEATURES: '/features',
  INVITATIONS: invitationId => `/invitations/${invitationId}`,
  INVITATIONS_RESEND: invitationId => `/invitations/${invitationId}/resend`,
  INVITE_FRIEND: '/invitations/friends',
  INVITE_INSIDER: '/invitations/insiders',
  INVITE_LEAD: '/invitations/hubspot/leads',
  INVITE_MEMBER: '/invitations/members',
  NETSPEND_ACCOUNTS_ACH: personId => `/netspend/accounts/${personId}/ach`,
  NETSPEND_ACCOUNTS_CARD: personId => `/netspend/persons/${personId}/card`,
  NETSPEND_ACCOUNTS_CLOSE: personId => `/netspend/accounts/${personId}/close`,
  NETSPEND_ACCOUNTS_EXTERNAL_BANK_TRANSFERS_DEPOSITS: personId => `/netspend/accounts/${personId}/external-bank-transfers/deposits`,
  NETSPEND_ACCOUNTS_EXTERNAL_BANK_TRANSFERS_WITHDRAWALS: personId => `/netspend/accounts/${personId}/external-bank-transfers/withdrawals`,
  NETSPEND_ACCOUNTS_EXTERNAL_BANKS: personId => `/netspend/accounts/${personId}/external-banks`,
  NETSPEND_ACCOUNTS_EXTERNAL_PRE_LINK: personId => `/netspend/accounts/${personId}/external-pre-link`,
  NETSPEND_ACCOUNTS_LEGAL: personId => `/netspend/accounts/${personId}/legal`,
  NETSPEND_ACCOUNTS_SCHEDULED: personId => `/netspend/accounts/${personId}/scheduled-transfer`,
  NETSPEND_ACCOUNTS_SCHEDULED_ID: (personId, transferId) => `/netspend/accounts/${personId}/scheduled-transfer/${transferId}`,
  NETSPEND_CARD_CLOSE: personId => `/netspend/cards/${personId}/close`,
  NETSPEND_CARD_LOCK: personId => `/netspend/cards/${personId}/lock`,
  NETSPEND_CARD_PIN: personId => `/netspend/cards/${personId}/pin`,
  NETSPEND_CARD_UNLOCK: personId => `/netspend/cards/${personId}/unlock`,
  NETSPEND_CARD_VERIFY: personId => `/netspend/cards/${personId}/verify`,
  NETSPEND_INITIALIZATIONS: '/netspend/initializations',
  NETSPEND_KYC: personId => `/netspend/persons/${personId}/kyc`,
  NETSPEND_PERSONS: '/netspend/persons',
  NETSPEND_PERSONS_BALANCE: personId => `/netspend/persons/${personId}/balance`,
  NETSPEND_PERSONS_CARD: personId => `/netspend/persons/${personId}/card`,
  NETSPEND_PERSONS_FEES: personId => `/netspend/persons/${personId}/fees`,
  NETSPEND_PERSONS_ID: personId => `/netspend/persons/${personId}`,
  NETSPEND_PERSONS_SESSIONS: personId => `/netspend/persons/${personId}/sessions`,
  NETSPEND_PERSONS_TRANSACTIONS: personId => `/netspend/persons/${personId}/transactions`,
  NETSPEND_SESSIONS: '/netspend/sessions',
  NETSPEND_STATEMENT: (personId, statementDate) => `/netspend/accounts/${personId}/statements?period=${statementDate}`,
  NETSPEND_STATEMENT_PERIODS: personId => `/netspend/accounts/${personId}/statements/periods`,
  NETSPEND_SUBACCOUNTS_DEPOSITS: personId => `/netspend/accounts/${personId}/sub-accounts/deposits`,
  NETSPEND_SUBACCOUNTS_WITHDRAWALS: personId => `/netspend/accounts/${personId}/sub-accounts/withdrawals`,
  NETSPEND_TEAMS_ACCOUNTS: teamId => `/netspend/teams/${teamId}/accounts`,
  NETSPEND_TEAMS_BALANCES: teamId => `/netspend/teams/${teamId}/balances`,
  NOTIFICATIONS: personId => `/notifications/${personId}`,
  NOTIFICATIONS_DISMISS_ALERT: personId => `/notifications/dismiss/${personId}`,
  NOTIFICATIONS_GET_TOASTS: personId => `/notifications/alerts/${personId}`,
  NOTIFICATIONS_SET_ALERTS_DELIVERED: alertId => `/notifications/alerts/deliver/${alertId}`,
  PERSON_PERMISSIONS: personId => `/persons/${personId}/permissions`,
  PERSON_STATUS: personId => `/persons/${personId}/status`,
  PERSONS: '/persons',
  PERSONS_ID: personId => `/persons/${personId}`,
  PERSONS_NOTIFICATIONS: personId => `/persons/${personId}/notifications`,
  PERSONS_OFFERS: personId => `/persons/${personId}/offers`,
  RESEND_OTP: '/auth/otp/send',
  RESET_PASSWORD: personId => `/auth/password/reset/${personId}`,
  TEAM_INVITATIONS: teamId => `/invitations/teams/${teamId}/`,
  TEAMS_PERSONS: teamId => `/teams/${teamId}/persons`,
  USERS: '/users',
  USERS_PATCH: userId => `/users/${userId}`,
  VALIDATE_INVITE_TOKEN: '/invitations/validate',
  VERIFY_OTP: '/auth/otp/verify',
  VERSION: '/version',
}
