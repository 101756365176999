import React, { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  CUSTOM_ERROR_MESSAGES,
  ENDPOINTS, HTTP_METHODS, ROUTES,
} from '@/common/constants'
import { useAuthentication, useSubmitForm } from '@/common/hooks'
import useValidateToken from '@/common/hooks/useValidateToken'
import { history } from '@/history'
import { addToast, TOAST_TYPES, updateAndSwitchUser } from '@/redux'
import { LeadEnrollmentForm } from '@components/EnrollmentForm'
import Loading from '@components/Loading'
import PageHeader from '@components/PageHeader'
import { EnrollmentSecurityPanel } from '@components/Panel'

import styles from './frame.module.scss'
import GetStarted from './getStarted'
import stylesjoinAcceptInvite from './joinLeadAcceptInvite.module.scss'

const JoinLeadAcceptInvite = ({ token, offer }) => {
  const { decoded } = useValidateToken({ token })

  useEffect(() => {
    // validate existing offer
    if (!offer) {
      dispatch(addToast({
        subtitle: 'Offer not found',
        title: 'Invalid offer',
        type: TOAST_TYPES.error,
      }))
      history.push(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.OFFER_EXPIRED))
    }
  }, [offer])

  const { enrollmentLogin } = useAuthentication()
  const { isLoading: auth0Loading } = useAuth0()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [pageClass, setPageClass] = useState('get-started')
  const [currentStep, setCurrentStep] = useState(0)

  const { apiErrors, setLoading, submitForm } = useSubmitForm()
  const nsSession = useSelector(state => state.netspend.session)
  const nsSessionLoaded = useSelector(state => state.netspend.sessionLoaded)

  useEffect(() => {
    if (decoded && nsSessionLoaded && !auth0Loading) {
      setIsLoading(false)
    }
  }, [decoded, nsSessionLoaded, auth0Loading])

  const setPageClassHandler = page => {
    setPageClass(page)
    setCurrentStep(currentStep + 1)
  }

  const onSubmit = async payload => {
    // create myfloc insider
    const myFlocLeadPayload = {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: payload.password,
      password2: payload.password2,
    }

    if (payload.middleName) myFlocLeadPayload.middleName = payload.middleName
    if (payload.displayName) myFlocLeadPayload.displayName = payload.displayName
    if (payload.mobile) myFlocLeadPayload.mobile = payload.mobile
    if ('smsConsent' in payload) myFlocLeadPayload.smsConsent = payload.smsConsent

    const createLeadResponse = await submitForm(ENDPOINTS.ACCEPT_LEAD + `?order=${offer}`, {
      manualLoading: true,
      method: HTTP_METHODS.POST,
      payload: myFlocLeadPayload,
    })

    if (createLeadResponse.response.status !== 201) {
      setLoading(false)
      dispatch(
        addToast({
          subtitle: createLeadResponse.data.error?.message,
          title: createLeadResponse.data.error?.title,
          type: TOAST_TYPES.error,
        })
      )
    }
    else {
      enrollmentLogin({ data: createLeadResponse.data }).then(() => {
        history.replace(ROUTES.VERIFY_EMAIL_OTP)
      })
      dispatch(updateAndSwitchUser(createLeadResponse.data))
    }
  }

  const classes = classNames(
    styles[pageClass],
    styles.iframe,
    isLoading && styles.loading
  )

  const classesJoinAccept = classNames(
    stylesjoinAcceptInvite.formContainer,
    'main-container'
  )
  return (
    <div className={classes}>
      {!isLoading && currentStep === 0 && (
        <GetStarted
          currentStep={currentStep}
          setPageClassHandler={setPageClassHandler}
        />
      )
      }
      {!isLoading && currentStep === 1 && (
        <>
          <PageHeader stepText={`Step ${currentStep} of 2`}>Enter your personal details</PageHeader>
          <div className={classesJoinAccept}>
            {
              (!decoded || !nsSession || decoded.loading || auth0Loading || !nsSessionLoaded)
                ? <Loading className='min-h-screen' />
                : <LeadEnrollmentForm
                  apiErrors={apiErrors}
                  loadPasswordField={true}
                  onSubmit={onSubmit}
                  prefill={{
                    email: decoded.email,
                    firstName: decoded.firstName,
                    lastName: decoded.lastName,
                  }}
                />
            }
            <EnrollmentSecurityPanel />
          </div>
        </>
      )
      }
    </div>
  )
}

JoinLeadAcceptInvite.propTypes = {
  offer: PropTypes.string.isRequired,

  token: PropTypes.string.isRequired,
}

export default JoinLeadAcceptInvite
