import {
  CUSTOM_ERROR_MESSAGES, ENROLLMENT, ROLES, ROUTES, STATUSES,
} from '@/common/constants'
import { history } from '@/history'

const inviteUserRoutes = [
  ROUTES.TEAM_ADD_FRIEND,
  ROUTES.TEAM_ADD_INSIDER,
  ROUTES.TEAM_ADD_TEAM_MEMBER,
]
const moveMoneyRoutes = [ROUTES.TRANSFER_FUNDS, ROUTES.MOVE_MONEY]

/**
 * Status Guards (from the useStatusHook)
 *
 * @param   {string}  pathName
 * @param   {Object}  activePersonStatus
 * @param   {Object}  myFlocPerson
 * @returns {Boolean}                    returns false to block route change, true to let it execute
 */
export const statusGuard = (pathName, activePersonStatus, myFlocPerson) => {
  if (!activePersonStatus) return true

  // redirect member or friend user with blocked netspend account
  if (
    activePersonStatus.nsAccountStatus === 'blocked' &&
    (myFlocPerson?.role === ROLES.TEAM_MEMBER || myFlocPerson?.role === ROLES.FRIEND)
  ) {
    history.push(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.ACCOUNT_BLOCKED))
    return false
  }

  // redirect user with blocked netspend account
  // if they try to invite users or move money
  if (
    activePersonStatus.nsAccountStatus === 'blocked' &&
    (inviteUserRoutes.includes(pathName) || moveMoneyRoutes.includes(pathName))
  ) {
    history.push(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.ACCOUNT_BLOCKED))
    return false
  }

  // redirect user whose lead has a blocked netspend account
  // if they try to invite users or move money
  if (
    activePersonStatus.nsLeadAccountStatus === 'blocked' &&
    (inviteUserRoutes.includes(pathName) || moveMoneyRoutes.includes(pathName))
  ) {
    history.push(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.ACCOUNT_BLOCKED_LEAD))
    return false
  }

  // redirect users without otp verification to verify page
  if (
    activePersonStatus.userStatus === STATUSES.UNVERIFIED_EMAIL &&
    pathName !== ROUTES.VERIFY_EMAIL_OTP
  ) {
    history.replace(ROUTES.VERIFY_EMAIL_OTP)
    return false
  }

  // redirect removed users to error screen
  if (activePersonStatus.personStatus === STATUSES.REMOVED_FROM_TEAM) {
    // TODO say something about their account
    history.replace('/error')
    return false
  }

  // redirect kyc rejected users
  if (
    activePersonStatus.nsPersonStatusKyc === STATUSES.KYC_REJECTED &&
    pathName !== ROUTES.ERROR_ENROLLMENT
  ) {
    history.replace(ROUTES.ERROR_ENROLLMENT)
    return false
  }

  if (
    // Important note: this states wont redirect to error enrollment flow
    !['', 'new', 'active'].includes(activePersonStatus.nsAccountStatus) &&
    (!activePersonStatus.nsPersonStatusKyc ||
      activePersonStatus.nsPersonStatusKyc === STATUSES.KYC_REJECTED) &&
    pathName !== ROUTES.ERROR_ENROLLMENT
  ) {
    history.replace(ROUTES.ERROR_ENROLLMENT)
    return false
  }

  // redirect kyc conditional users
  if (
    activePersonStatus.nsPersonStatusKyc === STATUSES.KYC_CONDITIONAL &&
    pathName !== ROUTES.KYC_QUESTIONS
  ) {
    history.push(ROUTES.KYC_QUESTIONS)
    return false
  }

  // NOTE: maybe also will need to validate STATUSES.UNCHECKED
  if (
    !['', 'active', 'new'].includes(activePersonStatus.nsAccountStatus) &&
    !activePersonStatus.nsPersonStatusOfac &&
    pathName !== ROUTES.ERROR_ENROLLMENT
  ) {
    history.replace(ROUTES.ERROR_ENROLLMENT)
    return false
  }

  // redirect users whose leads have not completed kyc
  if (
    ![''].includes(activePersonStatus.nsLeadAccountStatus) &&
    (activePersonStatus.nsLeadStatusKyc === STATUSES.KYC_CONDITIONAL ||
      activePersonStatus.nsLeadStatusKyc === STATUSES.KYC_REJECTED) &&
    pathName !== ROUTES.ERROR_ENROLLMENT &&
    pathName !== ROUTES.KYC_QUESTIONS
  ) {
    history.replace(ROUTES.ERROR_ENROLLMENT)
    return false
  }

  // redirect users whose leads have not completed ofac
  if (
    !['', 'new', 'active'].includes(activePersonStatus.nsLeadAccountStatus) &&
    !activePersonStatus.nsLeadStatusOfac &&
      pathName !== ROUTES.ERROR_ENROLLMENT
  ) {
    history.replace(ROUTES.ERROR_ENROLLMENT)
    return false
  }

  // redirect logged-in user on Join Insider / Team Member form
  // except if they have a myfloc account but no netspend account
  if (
    (pathName === ROUTES.JOIN_INSIDER || pathName === ROUTES.JOIN_TEAM_MEMBER) &&
    activePersonStatus.enrollmentFlow !== 'myfloc-account-created'
  ) {
    history.push(ROUTES.DASHBOARD)
    return false
  }

  // retry netspend for insiders and team members
  if (activePersonStatus?.enrollmentFlow === ENROLLMENT.MF_CREATED) {
    switch (myFlocPerson?.role) {
      // redirect insider to create netspend
      case ROLES.INSIDER:
        if (pathName !== ROUTES.JOIN_INSIDER) {
          history.push(ROUTES.JOIN_INSIDER)
          return false
        }
        break

      // redirect team member to create netspend
      case ROLES.TEAM_MEMBER:
        if (pathName !== ROUTES.JOIN_TEAM_MEMBER) {
          history.push(ROUTES.JOIN_TEAM_MEMBER)
          return false
        }
        break

      default:
    }
  }

  return true
}
