import React from 'react'

import PropTypes from 'prop-types'

import joinlead from '@/assets/images/joinlead.png'
import Button from '@/common/components/Button'
import PageHeader from '@/common/components/PageHeader'
import RobitStepper from '@/common/components/Stepper/RobitStepperLead'

import style from './getStarted.module.scss'

const GetStarted = ({ setPageClassHandler }) => {
  const steps = [
    { text: 'Enroll in myFloc' },
    { text: 'Set up and fund your myFloc Card Account' },
    { text: 'Build your myFloc Team' },
  ]

  return (
    <div>
      <PageHeader> Get started with myFloc </PageHeader>
      <div className={style.container}>
        <div className='flex flex-col md:flex-row md:justify-between'>
          {/* config for stepper */}

          <div className={style.control_container + ' md:mt-20'}>
            <div className='mx-auto md:mt-10'>
              <RobitStepper
                large={true}
                steps={steps}
              />
            </div>
            <div className='flex'>
              <Button
                className={style.button}
                disabled={false}
                onClick={() => setPageClassHandler('basic-information')}
                value={'get-started'} >
                Get Started
              </Button>
            </div>
          </div>
          <div className={style.image}>
            <img
              alt='myFloc hero'
              className='float-right object-contain'
              height={606}
              src={joinlead}
              style={{
                height: 'intrinsic', // layout-rendering fix for safari. see: https://stackoverflow.com/a/61267144
              }}
              width={606}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

GetStarted.propTypes = {
  /**
   * Current step of enrollment based on stepper
   * */
  currentStep: PropTypes.number,

  /**
   * Function to set page class
   * */
  setPageClassHandler: PropTypes.func,
}

export default GetStarted
