import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import styling from './stepper.module.scss'

export const RobitStepper = ({ className, currentStep = 0, large, steps, subtitle, title }) => {
  const containerClasses = classNames('flex-column', className)

  const rows = steps.map((step, index) => {
    const outerRowClass = classNames(
      large && styling.large,
      index + 1 === currentStep && styling.current,
      currentStep > index + 1 && styling.complete
    )
    const innerRowClass = classNames(
      typeof steps[index].handleClick === 'function' && 'cursor-pointer',
      'flex',
      'flex-row',
      'items-center'
    )

    return (
      <div className={outerRowClass} key={'stepperRow' + index}>
        <div className={innerRowClass} onClick={step.handleClick}>
          <div
            className={styling.circle}
            style={{
              height: '4.8rem',
              marginRight: '0',
              minHeight: '4.8rem',
              minWidth: '4.8rem',
              width: '4.8rem',
            }}
          > {index + 1} </div>
          <div
            style={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: '1',
              flexShrink: '1',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              marginLeft: '3rem',
            }}>
            <div
              className='text-secondary'
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '21px',
                whiteSpace: 'pre-wrap',
              }}> Step {index + 1}</div>
            <div
              style={{
                alignItems: 'top',
                fontSize: '20px',
                fontWeight: 'normal',
                lineHeight: '23px',
                marginLeft: '0.5rem',
                whiteSpace: 'pre-wrap',
              }}>{step.text}</div>
          </div>
        </div>

        { /* space into the next row */ }
        <div className='flex-grow mb-4' />

        {index < steps.length - 1 && (
          <div className={styling['separator-container']}>
            <div className={styling.separator + ' h-11'} />
          </div>
        )}
        <div className='flex-grow mb-4' />
      </div>
    )
  })

  return (
    <div className={containerClasses}>
      {title && <div className='mb-5 font-bold text-1-5xl text-secondary'>{title}</div>}
      {rows}
      {subtitle && <div className='mt-6 text-lg font-medium text-primary'>{subtitle}</div>}
    </div>
  )
}

RobitStepper.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The current step 1 indexed, the lower ones will be checked
   */
  currentStep: PropTypes.number,
  /**
   * Bigger spacing between
   */
  large: PropTypes.bool,

  /**
   * Need an array of steps
   */
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Lower subtitle
   */
  subtitle: PropTypes.string,
  /**
   * Upper title
   */
  title: PropTypes.string,
}

export default RobitStepper
