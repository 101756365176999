import React from 'react'

import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Loading from '@/common/components/Loading'
import { ROUTES } from '@/common/constants'
import { history } from '@/history'
import { createSession } from '@/redux'
import { updateAndSwitchTeam } from '@/redux/user'

import { TeamCard } from './TeamCard/TeamCard'

export const BuildListTeam = ({
  teamList,
  rolesToNumbersMap,
}) => {
  const baseClasses = 'max-w-5xl bg-white rounded mx-auto p-8 px-5 md:px-8 mt-8'
  const classes = {
    primarySection: 'flex flex-row items-center bg-primary text-white font-bold py-2 px-4 mt-6',
    wrapper: baseClasses,
  }
  const dispatch = useDispatch()

  const validateStatus = team => {
    let verbose = true
    if (team.nsAccountStatus === 'blocked' || team.nsAccountStatus === 'closed') {
      verbose = false
    }
    if (verbose && team.nsKycStatus === 'rejected') {
      verbose = false
    }
    if (verbose && !['', 'active', 'new'].includes(team.nsAccountStatus) && team.nsOfacStatus === '') {
      verbose = false
    }
    return verbose
  }
  const teamsCard = Object.values(teamList)
    .filter(team => validateStatus(team))
    .sort((a, b) => (
      rolesToNumbersMap[a.role] - rolesToNumbersMap[b.role]
    )).map(person => {
      const handleSelect = e => {
        dispatch(updateAndSwitchTeam(person))
        dispatch(createSession())
        history.push(ROUTES.DASHBOARD)
      }
      return (
        <TeamCard
          handleSelect={handleSelect}
          key={person.id}
          member={person}
        />
      )
    })

  return teamList ? (
    <div className={classes.wrapper}>
      <div className='md:flex md:flex-col' key='teamList'>
        {teamsCard}
      </div>
    </div>
  ) : (
    <Loading />
  )
}

BuildListTeam.propTypes = {
  /**
   * Map of roles to numbers
   */
  rolesToNumbersMap: PropTypes.object,

  /**
   * All team members for this section sorted by role
   */
  teamList: PropTypes.array,
}

export default BuildListTeam
