import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'
import { useForm } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { ReactComponent as PrintingIcon } from '@/assets/svg/printing_machine.svg'
import FormAddress from '@/common/components/FormAddress'
import HiddenPanel from '@/common/components/HiddenPanel'
import PageHeader from '@/common/components/PageHeader'
import {
  ENDPOINTS,
  HTTP_METHODS,
  NETSPEND_ERROR_MESSAGES,
  PDFS,
  ROUTES,
  SSN_VERIFY,
} from '@/common/constants'
import { ADMIN_MINIMUM_AGE, DOB_VERIFY, OPEN_CARD_ACCOUNT_LEGAL } from '@/common/constants/info'
import useSubmitForm from '@/common/hooks/useSubmitForm'
import { isEmpty, removeEmptyNested } from '@/common/utils'
import { addressSchema, cardAccountSchema } from '@/common/validations'
import { history } from '@/history'
import {
  addToast,
  createCardBatch,
  getMyFlocPerson,
  getOfferByPersonId,
  getUser,
  selectMyFlocPerson,
  setPerson,
  setKycQuestions,
  TOAST_TYPES,
  setLoadingCursor,
} from '@/redux'
import { PdfTable } from '@/views/createCardAccount/ccaComponents'
import AnimateIn from '@components/AnimateIn'
import Button from '@components/Button'
import FormCheckbox from '@components/FormCheckbox'
import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'

const LOCAL_STORAGE_KEY = 'createCardAccountStep1'

const StepOne = props => {
  const { apiErrors, loading, showValidationToast, submitForm } = useSubmitForm()

  const [mailingSchema, setMailingSchema] = useState(null)
  const [showBdayInfo, setShowBdayInfo] = useState(false)
  const [showSocialinfo, setShowSocialinfo] = useState(false)

  const myFlocPerson = useSelector(selectMyFlocPerson)
  const user = useSelector(state => state.user.user)
  const offer = useSelector(state => state.user.offer)

  const dispatch = useDispatch()

  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: { sameAddress: true },
    mode: 'onBlur',
    resolver: yupResolver(cardAccountSchema.concat(mailingSchema)),
  })

  // Preload information from save for later
  useEffect(() => {
    dispatch(getMyFlocPerson())
    if (!offer) {
      dispatch(getOfferByPersonId())
    }
  }, [offer, dispatch])

  const loadAddressValues = (addressType, address) => {
    if (myFlocPerson[addressType]) {
      if (!address) {
        setValue(addressType, myFlocPerson[addressType], { shouldValidate: false })
      }
      else {
        Object.entries(address)
          .forEach(
            ([key, val]) => !val && setValue(`${addressType}.${key}`, myFlocPerson[addressType][key])
          )
      }
    }
  }
  useEffect(() => {
    const values = getValues()
    if (!values.firstName) {
      setValue('firstName', user.firstName, { shouldValidate: false })
    }
    if (!values.middleName) {
      setValue('middleName', user.middleName, { shouldValidate: false })
    }
    if (!values.lastName) {
      setValue('lastName', user.lastName, { shouldValidate: false })
    }

    // Load the residence address if its empty
    loadAddressValues('residenceAddress', values.residenceAddress)
    if (
      !isEqual(myFlocPerson.residenceAddress, myFlocPerson.shippingAddress) &&
      !isEmpty(myFlocPerson.shippingAddress)
    ) {
      loadAddressValues('shippingAddress', values.shippingAddress)
      setValue('sameAddress', false)
    }

    // @@ KYC Conditional - uncomment for testing
    // setValue('firstName', 'JUANA', { shouldValidate: false })
    // setValue('lastName', 'ANGELA', { shouldValidate: false })
    // setValue('residenceAddress.line1', '50189 W Montcalm St', { shouldValidate: false })
    // setValue('residenceAddress.city', 'Greenville', { shouldValidate: false })
    // setValue('residenceAddress.region', 'MI', { shouldValidate: false })
    // setValue('residenceAddress.postal', '48838', { shouldValidate: false })
    // setValue('sameAddress', true, { shouldValidate: false })
    // setValue('dob', '10/01/1978', { shouldValidate: false })
    // setValue('social', '887117324', { shouldValidate: false })

    // KYC Success - uncomment for testing
    // setValue('firstName', 'NORRIS', { shouldValidate: false })
    // setValue('lastName', 'FADER', { shouldValidate: false })
    // setValue('residenceAddress.line1', '31677 WICK ROAD', { shouldValidate: false })
    // setValue('residenceAddress.city', 'ROMULUS', { shouldValidate: false })
    // setValue('residenceAddress.region', 'MI', { shouldValidate: false })
    // setValue('residenceAddress.postal', '48174', { shouldValidate: false })
    // setValue('sameAddress', true, { shouldValidate: false })
    // setValue('dob', '08/07/1961', { shouldValidate: false })
    // setValue('social', '870012934', { shouldValidate: false })
    // user_custom / {"version":2}
  }, [myFlocPerson])

  // Persist keys
  useFormPersist(
    LOCAL_STORAGE_KEY,
    { setValue, watch },
    {
      exclude: ['dob', 'reviewFees', 'social', 'termsAgreement'],
      storage: window.localStorage,
    }
  )

  // Conditionally require mailing address
  const sameAddress = watch('sameAddress')
  useEffect(() => {
    if (sameAddress) {
      clearErrors('shippingAddress')
      setMailingSchema(null)
    }
    else {
      setMailingSchema(
        yup.object({
          shippingAddress: addressSchema,
        })
      )
    }
  }, [sameAddress])

  const preparePayload = payload => {
    payload = removeEmptyNested(payload)

    if (payload.sameAddress) {
      delete payload.shippingAddress
    }
    if (!isEmpty(payload.shippingAddress)) {
      payload.shippingAddress.country = 'US'
    }
    if (!isEmpty(payload.residenceAddress)) {
      payload.residenceAddress.country = 'US'
    }

    delete payload.dob
    delete payload.social
    delete payload.reviewFees
    delete payload.sameAddress
    delete payload.termsAgreement

    return payload
  }

  const onSubmit = async payload => {
    // Encrypt the sensitive info
    try {
      const [MM, DD, YYYY] = payload.dob.split('/')
      const encryptedData = await window.NetspendSDK.session.encrypt({
        date_of_birth: `${YYYY}-${MM}-${DD}`,
        government_id: { type: 'ssn', value: payload.social.replace(/\s/g, '') },
      })
      payload.residenceAddress.country = 'US'
      if (payload.sameAddress) {
        delete payload.shippingAddress
      }
      else {
        payload.shippingAddress.country = 'US'
      }
      delete payload.dob
      delete payload.social
      delete payload.reviewFees
      delete payload.sameAddress
      delete payload.termsAgreement

      const { data } = await submitForm(`${ENDPOINTS.NETSPEND_PERSONS}/${myFlocPerson.id}`, {
        manualLoading: true,
        method: HTTP_METHODS.POST,
        payload: {
          ...preparePayload(payload),
          encryptedData,
        },
      })

      // Set netspend person
      if (data.netspendPerson) dispatch(setPerson(data.netspendPerson))

      if (
        data?.netspendPerson?.personStatusKyc === 'approved' &&
        data?.netspendPerson?.personStatusOfac === 'approved'
      ) {
        await dispatch(createCardBatch())
        delete localStorage[LOCAL_STORAGE_KEY]
        history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO)
      }
      else if (
        data.netspendPerson?.personStatusKyc === 'conditional' &&
        data.netspendPerson?.personStatusOfac === 'approved'
      ) {
        dispatch(setKycQuestions(data.encryptedData))
        history.replace(ROUTES.KYC_QUESTIONS)
      }
      else if (
        data?.netspendPerson?.personStatusKyc === 'rejected' ||
        data?.netspendPerson?.personStatusOfac === 'rejected'
      ) {
        history.replace(ROUTES.ERROR_ENROLLMENT)
      }
      else if (!data?.error?.toast) {
        history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC))
      }
      dispatch(setLoadingCursor(false))
    }
    catch (e) {
      history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC))
    }
  }

  const onSaveForLater = async () => {
    const { response } = await submitForm(ENDPOINTS.PERSONS_ID(myFlocPerson.id), {
      method: 'PATCH',
      payload: preparePayload(getValues()),
    })

    if (response.status === 204) {
      dispatch(
        addToast({
          subtitle:
            'Your details were saved, you can return to the card account creation process at any time',
          title: 'Success',
          type: TOAST_TYPES.success,
        })
      )
      dispatch(getUser())
      history.push(ROUTES.DASHBOARD)
    }
  }

  return (
    <>
      <PageHeader stepText='Step 1 of 5'>Set up your myFloc Card Account</PageHeader>

      <div className='relative flex-col text-left main-container  max-w-5xl'>
        <form onSubmit={handleSubmit(onSubmit, showValidationToast)}>
          <div className='text-sm my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            *Required field
          </div>
          <div className='mt-1 text-lg my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            Please enter and review your details to create your myFloc Card Account. Card use and
            all features are subject to activation and ID verification.&#185;
          </div>

          {/* Personal Info */}
          <div className='flex flex-row justify-between mt-6 my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <FormInput
              autoComplete='given-name'
              errors={errors}
              hintContent='Use full legal name.'
              label='First Name'
              name='firstName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
              required
            />
            <div className='mx-2' />
            <FormInput
              autoComplete='middle-name'
              errors={errors}
              label='Middle (optional)'
              name='middleName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
            />
          </div>
          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <FormInput
              autoComplete='family-name'
              errors={errors}
              label='Last Name'
              name='lastName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
              required
            />
          </div>

          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <FormAddress
              apiErrors={apiErrors}
              errors={errors}
              name='residenceAddress'
              register={register}
            />
          </div>

          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <FormCheckbox
              className='mb-0'
              id='joinFriendSmsConsent'
              name='sameAddress'
              register={register}
              subtitle='Mail my card to this address'
              plain
            />
          </div>

          <div className='w-full border-t my-0 mx-auto max-w-[45.2rem] py-0 px-1' />

          {/* Animate in this section based on the checkbox above */}
          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <AnimateIn maxHeight={740} show={!sameAddress}>
              <h4 className='mt-9 mb-8'>Where should we mail your myFloc card?</h4>

              <FormAddress
                apiErrors={apiErrors}
                errors={errors}
                name='shippingAddress'
                register={register}
              />

              <div className='w-full border-t' />
            </AnimateIn>
          </div>

          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <FormInput
              autoComplete='bday'
              className='mt-7'
              errors={errors}
              hintContent='Must be at least 18 years old.'
              inputClass='w-60'
              label='Date of Birth (MM/DD/YYYY)'
              labelButtonAction={() => setShowBdayInfo(true)}
              labelButtonText='Why Do We Need This?'
              name='dob'
              register={register}
              type={FORM_INPUT_TYPES.date}
              validationErrors={apiErrors?.validationErrors}
              required
            />
          </div>

          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <HiddenPanel setShow={setShowBdayInfo} show={showBdayInfo}>
              {DOB_VERIFY} {ADMIN_MINIMUM_AGE}
            </HiddenPanel>
          </div>

          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <FormInput
              errors={errors}
              inputClass='w-60'
              label='Social Security Number'
              labelButtonAction={() => setShowSocialinfo(true)}
              labelButtonText='Why Do We Need This?'
              name='social'
              register={register}
              type={FORM_INPUT_TYPES.social}
              validationErrors={apiErrors?.validationErrors}
              required
            />
          </div>

          <div className='w-full border-t my-0 mx-auto max-w-[50rem] py-0 px-1' />

          <div className='mt-7 mb-12 text-lg my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <p>
              {`You signed-up for a special offer where you paid a
              one-time setup fee of $${offer && ['LTOS_0', null].includes(offer.id) ? 0 : 35}. and are`}{' '}
              <strong>{`paying $${offer && offer.monthlyFeeAmount} per month`}</strong>
              {' '}for your myFloc account monthly fee.
            </p>
            <p>
              We are required to disclouse the full-price myfloc
              account monthly fee along with other Card Account
              fees in the box below.
            </p>
            <p>
              After setting up your myFloc Card Account, you will
              continue to be charged the special offer rate of{' '}
              <strong>{`$${offer && offer.monthlyFeeAmount} per month`}</strong>
              {' '}for your myFloc account monthly fee. This
              reduced monthly fee is subject to change. You will be
              notified prior to any increase in the monthly fee.
            </p>
          </div>

          <div className='w-full text-lg font-bold mt-2 mb-2 my-0 mx-auto max-w-[50rem] py-0 px-1'>
            Please review the following and agree before proceeding.
          </div>

          <div className='justify-center items-center flex flex-row mb-2 mt-7 my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <Button
              className='p-0 m-0 font-bold mt-2 md:mt-0 md:ml-2.5'
              onClick={() => window.open(PDFS.FEES_ASSOCIATED_WITH_YOUR_CARD, '_blank')}
              type='button'
              primary
              text
            >
              <div className='flex flex-row justify-center items-center'>
                <PrintingIcon className='mr-2 fill-current' height='16px' />
                <span>Print or save as PDF</span>
              </div>
            </Button>
          </div>

          <div className='mx-auto mb-2 max-w-5xl'>
            <div className='flex flex-row'>
              <PdfTable />
            </div>
          </div>

          <div className='my-0 mx-auto max-w-[54.2rem] py-0 px-1'>
            <HiddenPanel setShow={setShowSocialinfo} show={showSocialinfo}>
              {SSN_VERIFY}
            </HiddenPanel>
          </div>

          <div className='my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <FormCheckbox
              errors={errors}
              id='joinFriendTermsAgreement'
              name='termsAgreement'
              register={register}
              validationErrors={apiErrors?.validationErrors}
              plain
            >
              <div className='text-base font-bold max-w-2xl'>
                By checking this box, I confirm I have read and agree to the terms and conditions of
                the{' '}
                <Button
                  className='p-0 m-0 font-bold'
                  onClick={() => window.open(PDFS.CARDHOLDER_AGREEMENT, '_blank')}
                  type='button'
                  primary
                  text
                >
                  Cardholder Agreement
                </Button>{' '}
                and the issuing bank's{' '}
                <Button
                  className='p-0 m-0 font-bold'
                  onClick={() => window.open(PDFS.NETSPEND_PRIVACY_POLICY, '_blank')}
                  type='button'
                  primary
                  text
                >
                  Privacy Policy
                </Button>{' '}
                and{' '}
                <Button
                  className='p-0 m-0 font-bold'
                  onClick={() => window.open(PDFS.ESIGN_DISCLOSURE, '_blank')}
                  type='button'
                  primary
                  text
                >
                  ESign Disclosure
                </Button>
                .<sup>*</sup>
              </div>
            </FormCheckbox>
          </div>

          {/* Actions */}
          <div className='flex flex-col items-center mt-10 mb-8 my-0 mx-auto max-w-[45.2rem] py-0 px-1'>
            <Button
              disabled={
                !isEmpty(errors) ||
                !isEmpty(apiErrors?.validationErrors) ||
                !watch(['termsAgreement'])
                  .every(x => x)
              }
              isLoading={loading}
            >
              Set up Account
            </Button>
            <Button className='mt-8' onClick={onSaveForLater} type='button' primary text>
              Save and Finish Later
            </Button>
          </div>
        </form>
      </div>

      {/* Bottom Legal */}
      <div className='mx-auto mb-12 max-w-5xl'>
        <div className='flex flex-row'>
          <div>1.</div>
          <div className='ml-2'>{OPEN_CARD_ACCOUNT_LEGAL}</div>
        </div>
      </div>
    </>
  )
}

export default StepOne
