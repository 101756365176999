export const ROUTES = {
  ACCOUNT: '/account',
  ACCOUNT_PERSON: (personId = '') => `/account?to=${personId}`,
  ACTIVATE_CARD: '/set-card-pin',
  CREATE_CARD_ACCOUNT: {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    STEP_ONE: '/create-card-account/step-1',
    STEP_TWO: '/create-card-account/step-2',
    STEP_THREE: '/create-card-account/step-3',
    STEP_FOUR: '/create-card-account/step-4',
    STEP_FIVE: '/create-card-account/step-5',
  },
  DASHBOARD: '/',
  DISPUTE_TRANSACTIONS: '/dispute-transactions',
  ERROR_ENROLLMENT: '/error-enrollment',
  ERROR_CUSTOM: message => `/error?custom=${message}`,
  ERROR404: '/404',
  JOIN_FRIEND: '/join-friend',
  JOIN_INSIDER: '/join-insider',
  JOIN_LEAD: '/join-lead',
  JOIN_TEAM_MEMBER: '/join-teamMember',
  KYC_QUESTIONS: '/kyc-questions',
  LOGGED_OUT: '/logged-out',
  LOGIN: '/login',
  LEAD_INVITE: '/invite-lead',
  MOVE_MONEY: '/move-money',
  NETSPEND_ERROR: type => `/error/bank?custom=${type}`,
  NOTIFICATIONS: '/notifications',
  PROFILE: '/profile',
  RESTRICTED_QA: '/restricted/qa',
  REPLACE_CARD: '/replace-card',
  REPLACE_CARD_ID: personId => `/replace-card?to=${personId}`,
  STATEMENTS: '/statements',
  TEAM_SELECTION: '/team-selection',
  TEAM: '/team',
  TEAM_ADD_FRIEND: '/team/add-friend',
  TEAM_ADD_INSIDER: '/team/add-insider',
  TEAM_ADD_TEAM_MEMBER: '/team/add-team-member',
  TEAM_REMOVE_MEMBER_ID: '/team/remove-member/:personIdToRemove',
  TEAM_SET_PERMISSIONS_ID: '/team/set-permissions/:id',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  TRANSFER_CONFIRMATION: '/transfer-confirmation',
  TRANSFER_DIRECT: '/transfer-direct',
  TRANSFER_REVIEW: '/transfer-review',
  TRANSFER_FUNDS: '/transfer',
  VERIFY_EMAIL_OTP: '/verify-email',
}

export const EXTERNAL_URLS = {
  FAQ: {
    BASE: 'https://help.myfloc.com/help',
  },
  PRIVACY_POLICY: 'https://www.myfloc.com/privacy-policy',
  TERMS_AND_CONDITIONS: 'https://www.myfloc.com/terms-and-conditions',
}

export const PDFS = {
  BANK_PRIVACY_POLICY: '/pdfs/pathward_privacy_policy.pdf',
  BANK_TRANSFER_ESIGN: '/pdfs/Bank_Transfer_ESign.pdf',
  BANK_TRANSFER_TERMS_AND_CONDITIONS: '/pdfs/Bank_Transfer_Terms_and_Conditions.pdf',
  CARDHOLDER_AGREEMENT: '/pdfs/Cardholder_Agreement.pdf',
  DIRECT_DEPOSIT_FORM: '/pdfs/Direct_Deposit_Form.pdf',
  ESIGN_DISCLOSURE: '/pdfs/myfloc_card_esign_agreement_pathward.pdf',
  PREPAID_CARD_FEES: '/pdfs/Prepaid_Card_fees.pdf',
  NETSPEND_PRIVACY_POLICY: '/pdfs/pathward_privacy_policy.pdf',
  FEES_ASSOCIATED_WITH_YOUR_CARD: '/pdfs/Fees_Associated_With_Your_myFloc_Card.pdf',
}
