import { useCallback, useEffect, useState } from 'react'

import jwtDecode from 'jwt-decode'
import { useSelector } from 'react-redux'

import { CUSTOM_ERROR_MESSAGES, ENDPOINTS, ROUTES } from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { history } from '@/history'

/**
 * Validate and decode JWTs
 *
 * @param   {Object}                                  props
 * @param   {string}                                  [props.token] the JTW token
 * @returns {{decoded, existingUser, loading, valid}}
 *
 */
export const useValidateToken = ({ token }) => {
  const { submitForm } = useSubmitForm()
  const [loading, setLoading] = useState(true)
  const [decoded, setDecoded] = useState(null)
  const [valid, setValid] = useState(null)
  const [existingUser, setExistingUser] = useState(null)
  const user = useSelector(state => state.user)

  // Validate
  const validateToken = useCallback(async () => {
    if (!loading) {
      setLoading(true)
    }
    const { response, data } = await submitForm(`${ENDPOINTS.VALIDATE_INVITE_TOKEN}?token=${token}`, {
      method: 'GET',
      noErrorToast: true,
    })
    setLoading(false)

    const verbose = response.status === 200
    if (verbose) {
      setValid(true)
      setExistingUser(data)
    }

    if (user.user && user.id !== data.user?.id) {
      history.replace(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.INVITATION_FOR_INCORRECT_USER))
    }

    if (verbose) {
      return
    }

    // token's not valid because:
    //   - user already enrolled and is on team -> redirect to login
    //   - invalid invitation -> show invitation error screen
    const invitationAccepted = data?.error?.code === 'INVITATION_ACCEPTED'
    history.replace(
      invitationAccepted
        ? ROUTES.LOGIN
        : ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.INVITATION_EXPIRED)
    )
  }, [submitForm, token, user])

  useEffect(() => {
    if (token && valid === null) {
      validateToken()
    }
  }, [token])

  // Decode
  useEffect(() => {
    if (token && valid) {
      try {
        setDecoded(jwtDecode(token))
      }
      catch (error) {
        history.replace(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.INVITATION_EXPIRED))
      }
    }
    else if (!token) {
      history.replace(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.INVITATION_EXPIRED))
    }
  }, [token, valid])

  return {
    decoded, existingUser, loading, valid,
  }
}
export default useValidateToken
